import { GQL_ENDPOINT } from '../../fetch';
import { fetchFromGql } from './fetch';
import { fetchJsonGql } from './fetch-json-gql';
/**
 * according to:
 * https://support.microsoft.com/en-us/topic/maximum-url-length-is-2-083-characters-in-internet-explorer-174e7c8a-6666-f4e0-6fd6-908b53c12246
 */
var GET_CHAR_LIMIT = 2048;
var safeForGetMethod = function (url) {
    return location.origin.length + url.length < GET_CHAR_LIMIT;
};
var getQueryParamsString = function (_a) {
    var extensions = _a.extensions, variables = _a.variables;
    var searchParams = new URLSearchParams();
    searchParams.set('extensions', JSON.stringify(extensions));
    if (variables) {
        searchParams.set('variables', JSON.stringify(variables));
    }
    return searchParams.toString();
};
export var fetchQueryParamsGql = function (args) {
    var url = "".concat(GQL_ENDPOINT, "?").concat(getQueryParamsString(args));
    if (safeForGetMethod(url)) {
        return fetchFromGql({ url: url });
    }
    else {
        return fetchJsonGql(args);
    }
};
