import { fetchFromGql } from './fetch';
var getFormDataBody = function (_a) {
    var query = _a.query, assocMap = _a.assocMap, variables = _a.variables, extensions = _a.extensions;
    var map = {};
    var body = new FormData();
    body.append('operations', JSON.stringify({ query: query, variables: variables, extensions: extensions }));
    assocMap.forEach(function (_a, key) {
        var name = _a.name;
        map[name] = ["variables.".concat(key)];
    });
    body.append('map', JSON.stringify(map));
    assocMap.forEach(function (_a) {
        var name = _a.name, value = _a.value;
        body.append(name, value);
    });
    return body;
};
export var fetchFormDataGql = function (args) {
    return fetchFromGql({
        body: getFormDataBody(args),
        method: 'post',
    });
};
