var isErrorWithMessage = function (error) {
    return typeof error === 'object' &&
        error !== null &&
        Object.hasOwnProperty.call(error, 'message');
};
export var getMessageFromError = function (error) {
    if (typeof error === 'string') {
        return error;
    }
    else if (isErrorWithMessage(error)) {
        return error.message;
    }
    return null;
};
