import { fetchQueryParamsGql } from './fetch-query-params-gql';
import { fetchFormDataGql } from './fetch-form-data-gql';
import { fetchJsonGql } from './fetch-json-gql';
export var fetchGql = function (_a) {
    var query = _a.query, variables = _a.variables, persistedQuery = _a.persistedQuery, assocMap = _a.assocMap, preferGet = _a.preferGet;
    var extensions = persistedQuery ? { persistedQuery: persistedQuery } : undefined;
    if (assocMap) {
        return fetchFormDataGql({
            query: query,
            variables: variables,
            assocMap: assocMap,
            extensions: extensions,
        });
    }
    if (preferGet && extensions && !query) {
        return fetchQueryParamsGql({
            variables: variables,
            extensions: extensions,
        });
    }
    else {
        return fetchJsonGql({
            query: query,
            variables: variables,
            extensions: extensions,
        });
    }
};
