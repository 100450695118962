var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var GraphQLError = /** @class */ (function (_super) {
    __extends(GraphQLError, _super);
    function GraphQLError(_a) {
        var message = _a.message, extensions = _a.extensions;
        var _this = _super.call(this, message) || this;
        // Setting the prototype manually, so we're able to use instanceof.
        // This is recommended approach by TypeScript for extending built-in classes:
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, GraphQLError.prototype);
        _this.extensions = extensions;
        return _this;
    }
    return GraphQLError;
}(Error));
export { GraphQLError };
