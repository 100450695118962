var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fetchFromGql } from './fetch';
var getStringifiedBody = function (_a) {
    var query = _a.query, variables = _a.variables, extensions = _a.extensions;
    return JSON.stringify(__assign(__assign(__assign({}, (!!query && { query: query })), (!!variables && { variables: variables })), (!!extensions && { extensions: extensions })));
};
export var fetchJsonGql = function (args) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return fetchFromGql({
        body: getStringifiedBody(args),
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
